import axios from 'axios'
import store from '../store/index'
import commonUtils from '@/plugins/commonUtils'

const REPORT_URL = process.env.VUE_APP_REPORT_URL

export default {
  getPdfConfig () {
    const config = { toolbar: false }
    const idConfig = {
      print: 'pdfPrint',
      pageNumber: 'pdfPageNumber',
      numPages: 'pdfNumPages'
    }
    return {
      config,
      idConfig
    }
  },
  // Arrival Notice
  async setArrivalNoticePdf (items, usrNo, ctrCd, bkgPlcCd) {
    const strParams = []
    strParams.push('usrNo=' + usrNo)
    strParams.push('ctrCd=' + ctrCd)
    strParams.push('plcCd=' + bkgPlcCd)

    const emailFiles = []

    // 개별 파일 Url 정보 셋팅
    items.forEach(p => {
      emailFiles.push(p.blNo + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf/arrival-notice?arrBlNo=' + p.blNo + '&' + strParams.join('&')
    })

    const arrBlNo = items.map(p => 'arrBlNo=' + p.blNo)

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/arrival-notice?' + arrBlNo.join('&') + '&' + strParams.join('&')),
      emailUrl: REPORT_URL + '/report/email/arrival-notice?' + arrBlNo.join('&') + '&' + strParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('A/N', rtn)
    return rtn
  },
  async setDeliveryOrder (items, usrNo, ctrCd, bkgPlcCd) {
    const strParams = []
    strParams.push('usrNo=' + usrNo)
    strParams.push('ctrCd=' + ctrCd)
    strParams.push('plcCd=' + bkgPlcCd)

    const arrParams = []
    const emailFiles = []

    // 개별 파일 Url 정보 셋팅
    items.forEach(p => {
      const tempParams = []
      tempParams.push('arrBlNo=' + p.blNo)
      tempParams.push('arrDoRno=' + p.doRno)
      tempParams.push('arrPodPortCd=' + p.podPortCd || '')
      tempParams.push('arrIssCargUno=' + p.issCargUno || '')
      tempParams.push('arrDoIssDt=' + p.doIssDt || '')
      tempParams.push('arrAgtCd=' + p.agtCd || '')

      arrParams.push(tempParams.join('&'))
      emailFiles.push(p.doRno + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf/delivery-order?' + tempParams.join('&') + '&' + strParams.join('&')
    })

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/delivery-order?' + arrParams.join('&') + '&' + strParams.join('&')),
      emailUrl: REPORT_URL + '/report/email/delivery-order?' + arrParams.join('&') + '&' + strParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('D/O', rtn)
    return rtn
  },
  async setBlInvoiceFreight (items, ctrCd, bkgPlcCd, agtCd, userId) {
    const strParams = []
    strParams.push('ctrCd=' + ctrCd)
    strParams.push('portCd=' + bkgPlcCd)
    strParams.push('agtNo=' + agtCd)
    strParams.push('userId=' + userId)

    const arrParams = []
    const emailFiles = []

    // 개별 파일 Url 정보 셋팅
    items.forEach(p => {
      const tempParams = []
      tempParams.push('arrInvIssNo=' + p.invIssNo)
      tempParams.push('arrTo=' + p.to || '')
      tempParams.push('arrRemark=' + p.remark || '')
      tempParams.push('arrFilter=' + p.filter || '')
      tempParams.push('arrBlNo=' + p.blNo || '')
      tempParams.push('arrEsign=' + p.esign || '')

      arrParams.push(tempParams.join('&'))
      emailFiles.push(p.invIssNo.replace(',', '_') + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf/bl-invoice-freight?' + tempParams.join('&') + '&' + strParams.join('&')
    })

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/bl-invoice-freight?' + arrParams.join('&') + '&' + strParams.join('&')),
      emailUrl: REPORT_URL + '/report/email/bl-invoice-freight?' + arrParams.join('&') + '&' + strParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('BL Invoice Freight', rtn)
    return rtn
  },
  async setBlProformaInvoiceId (items, userId, cstNo, ctrCd, bkgPlcCd, agtCd) {
    // 인도네시아(ID) Proforma Invoice (정산발행)
    let strParam = []
    strParam.push('ctrCd=' + ctrCd)
    strParam.push('bkgPlcCd=' + bkgPlcCd)
    strParam.push('usrNo=' + userId)
    strParam.push('cstNo=' + cstNo)
    strParam.push('agtCd=' + agtCd)

    let arrParams = []
    let emailFiles = []

    // 개별 파일 Url 정보 셋팅
    items.forEach(p => {
      const tempParams = 'arrBlNo=' + p.blNo

      arrParams.push(tempParams)
      emailFiles.push(p.blNo.replace(',', '_') + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf/bl-proforma-invoice-id?' + tempParams + '&' + strParam.join('&')
    })

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/bl-proforma-invoice-id?' + arrParams.join('&') + '&' + strParam.join('&')),
      emailUrl: REPORT_URL + '/report/email/bl-proforma-invoice-id?' + arrParams.join('&') + '&' + strParam.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('BL Proforma Freight(ID)', rtn)

    return rtn
  },
  async setCertificate (items, ctrCd, logInfo) {
    const strParams = []
    strParams.push('ctrCd=' + ctrCd)
    strParams.push('userId=' + logInfo.logUserId || '')
    strParams.push('plcCd=' + logInfo.logPlcCd || '')

    const arrParams = []
    const emailFiles = []

    // 개별 파일 Url 정보 셋팅
    items.forEach(p => {
      const tempParams = []
      tempParams.push('arrCrtfNo=' + p.crtfNo)
      tempParams.push('arrToNm=' + (p.toNm ? encodeURIComponent(p.toNm) : ''))
      tempParams.push('arrAttn=' + (p.attn ? encodeURIComponent(p.attn) : ''))
      tempParams.push('arrVoyNo=' + p.voyNo || '')
      tempParams.push('arrPolPortNm=' + (p.polPortNm ? encodeURIComponent(p.polPortNm) : ''))
      tempParams.push('arrPodPortNm=' + (p.podPortNm ? encodeURIComponent(p.podPortNm) : ''))
      tempParams.push('arrPolPortCtr=' + (p.polPortCtr ? encodeURIComponent(p.polPortCtr) : ''))
      tempParams.push('arrPodPortCtr=' + (p.podPortCtr ? encodeURIComponent(p.podPortCtr) : ''))
      tempParams.push('arrToVsl=' + p.toVsl || '')
      tempParams.push('arrToVoy=' + p.toVoy || '')
      tempParams.push('arrSign=' + p.sign || '')
      tempParams.push('arrTitle=' + encodeURIComponent(p.crtfTitle) || '')
      tempParams.push('arrAgtCd=' + p.agtCd || '')

      arrParams.push(tempParams.join('&'))
      emailFiles.push(p.crtfNo + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf/certificate?' + tempParams.join('&') + '&' + strParams.join('&')
    })

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/certificate?' + arrParams.join('&') + '&' + strParams.join('&')),
      emailUrl: REPORT_URL + '/report/email/certificate?' + arrParams.join('&') + '&' + strParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('Certificate', rtn)
    return rtn
  },
  async setBlCopy (items, usrVo) {
    // console.log('setBlCopy items @@@@@ ', items)

    const strParams = []
    strParams.push('ctrCd=' + usrVo.ctrCd)
    strParams.push('bkgPlcCd=' + usrVo.bkgPlcCd)
    strParams.push('userId=' + usrVo.userId)
    strParams.push('agtCd=' + usrVo.agtCd)
    strParams.push('ctrCurCd=' + usrVo.ctrCurCd)

    const arrParams = []
    const emailFiles = []

    // 개별 파일 Url 정보 셋팅
    items.forEach(p => {
      const tempParams = []
      tempParams.push('arrBlNo=' + p.blNo)
      tempParams.push('arrRiderYn=' + p.riderYn || '')
      tempParams.push('arrPorDlyYn=' + p.porDlyYn || '')
      tempParams.push('arrNoCnee=' + p.noCnee || '')
      tempParams.push('arrNoNotify=' + p.noNotify || '')
      tempParams.push('arrClauseYn=' + p.clauseYn || '')
      tempParams.push('arrExRateYn=' + p.exRateYn || '')
      tempParams.push('arrElPrintYn=' + p.elPrintYn || '')
      tempParams.push('arrSignYn=' + p.signYn || '')
      tempParams.push('arrBlcopyKrSignYn=' + p.eSignYn || '')
      tempParams.push('arrBlCopyOblYn=' + p.oblYn || '')

      arrParams.push(tempParams.join('&'))
      emailFiles.push(p.blNo + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf/bl-copy?' + tempParams.join('&') + '&' + strParams.join('&')
    })

    if (usrVo.oblYn === 'Y') {
      if (usrVo.ctrCd === 'KR') {
        await commonUtils.alertDefault('Copy의 내용은 발행된 Original B/L과 동일하며, 서명(사인)은 생략됩니다.')
      } else {
        await commonUtils.alertDefault('The content of the copy is the same as the original B/L. And the signature is omitted.')
      }
    }

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/bl-copy?' + arrParams.join('&') + '&' + strParams.join('&')),
      emailUrl: REPORT_URL + '/report/email/bl-copy?' + arrParams.join('&') + '&' + strParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('B/L Copy', rtn)
    return rtn
  },
  async setItextBlCopy (items, usrVo) {
    const strParams = []
    strParams.push('ctrCd=' + usrVo.ctrCd)
    strParams.push('plcCd=' + usrVo.plcCd)
    strParams.push('userId=' + usrVo.userId)
    strParams.push('agtCd=' + usrVo.agtCd)
    strParams.push('ctrCurCd=' + usrVo.ctrCurCd)

    const arrParams = []
    const emailFiles = []

    // 개별 파일 Url 정보 셋팅
    items.forEach(p => {
      const tempParams = []
      tempParams.push('arrBlNo=' + p.blNo)
      arrParams.push(tempParams.join('&'))
      emailFiles.push(p.blNo + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf2/shipping-request?' + tempParams.join('&') + '&' + strParams.join('&')
    })

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf2/shipping-request?' + arrParams.join('&') + '&' + strParams.join('&')),
      emailUrl: REPORT_URL + '/report/email2/shipping-request?' + arrParams.join('&') + '&' + strParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('B/L Copy', rtn)
    return rtn
  },
  async setItextDg (items) {
    const arrParams = []
    const emailFiles = []

    // 개별 파일 Url 정보 셋팅
    items.forEach(p => {
      const tempParams = []
      tempParams.push('arrBkgNo=' + p.bkgNo)
      arrParams.push(tempParams.join('&'))
      emailFiles.push(p.bkgNo + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf2/dg-declaration?' + tempParams.join('&')
    })

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf2/dg-declaration?' + arrParams.join('&')),
      emailUrl: REPORT_URL + '/report/email2/dg-declaration?' + arrParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('DG Declaration', rtn)
    return rtn
  },
  async setBookingConfirm (items, usrNo, usrCtrCd) {
    const strParams = []
    strParams.push('usrNo=' + usrNo)
    strParams.push('usrCtrCd=' + usrCtrCd)

    const arrParams = []
    // 개별 파일 Url 정보 셋팅
    const emailFiles = []

    console.log('setBookingConfirm items >>>> ', items)

    items.forEach(p => {
      const tempParams = []
      tempParams.push('arrBkgNo=' + p.bkgNo)
      tempParams.push('arrBlNo=' + p.srBlNo)
      tempParams.push('arrGubun=' + p.gubun || '')
      tempParams.push('arrPlcCd=' + p.plcCd || '')
      tempParams.push('arrCtrCd=' + p.ctrCd || '')

      arrParams.push(tempParams.join('&'))
      emailFiles.push(p.bkgNo + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf/booking-confirm?' + tempParams.join('&') + '&' + strParams.join('&')
    })

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/booking-confirm?' + arrParams.join('&') + '&' + strParams.join('&')),
      emailUrl: REPORT_URL + '/report/email/booking-confirm?' + arrParams.join('&') + '&' + strParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('bookingConfirm', rtn)
    return rtn
  },
  async setDelayNotice (items, intOut, logInfo) {
    console.log('setDelayNotice @@@@@ ', items)

    const strParams = []
    strParams.push('inOut=' + intOut)
    strParams.push('plcCd=' + logInfo.logPlcCd)
    strParams.push('ctrCd=' + logInfo.logCtrCd)
    strParams.push('userId=' + logInfo.logUserId)

    const arrParams = []
    // 개별 파일 Url 정보 셋팅
    const emailFiles = []
    items.forEach(p => {
      const tempParams = []
      tempParams.push('arrAsPort=' + p.port)
      tempParams.push('arrPrtGubun=' + p.prtGubun || '')
      tempParams.push('arrParm1=' + p.parm1 || '')
      tempParams.push('arrComDocNo=' + p.docNo || '')

      arrParams.push(tempParams.join('&'))
      emailFiles.push(p.parm1 + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf/delay-notice?' + tempParams.join('&') + '&' + strParams.join('&')
    })

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/delay-notice?' + arrParams.join('&') + '&' + strParams.join('&')),
      emailUrl: REPORT_URL + '/report/email/delay-notice?' + arrParams.join('&') + '&' + strParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('delayNotice', rtn)
    return rtn
  },
  async setVesselChange (items, logInfo) {
    const strParams = []
    strParams.push('usrNo=' + logInfo.logUserId)

    const arrParams = []
    // 개별 파일 Url 정보 셋팅
    const emailFiles = []
    items.forEach(p => {
      const tempParams = []
      tempParams.push('arrAsPort=' + p.port)
      tempParams.push('arrPrtGubun=' + p.prtGubun || '')
      tempParams.push('arrParm1=' + p.parm1 || '')
      tempParams.push('arrComDocNo=' + p.docNo || '')
      tempParams.push('ctrCd=' + logInfo.logCtrCd || '')

      arrParams.push(tempParams.join('&'))
      emailFiles.push(p.parm1 + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf/vessel-change?' + tempParams.join('&') + '&' + strParams.join('&')
    })

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/vessel-change?' + arrParams.join('&') + '&' + strParams.join('&')),
      emailUrl: REPORT_URL + '/report/email/vessel-change?' + arrParams.join('&') + '&' + strParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('vesselChange', rtn)
    return rtn
  },
  async setRollOverNotice (items, logInfo) {
    const strParams = []
    strParams.push('usrNo=' + logInfo.logUserId)
    strParams.push('ctrCd=' + logInfo.logCtrCd || '')

    const arrParams = []
    // 개별 파일 Url 정보 셋팅
    const emailFiles = []
    items.forEach(p => {
      const tempParams = []
      tempParams.push('arrParm1=' + p.parm1 || '')
      tempParams.push('arrAsPort=' + p.port)

      arrParams.push(tempParams.join('&'))
      emailFiles.push(p.parm1 + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf/roll-over-notice?' + tempParams.join('&') + '&' + strParams.join('&')
    })

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/roll-over-notice?' + arrParams.join('&') + '&' + strParams.join('&')),
      emailUrl: REPORT_URL + '/report/email/roll-over-notice?' + arrParams.join('&') + '&' + strParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('RollOverNotice', rtn)
    return rtn
  },
  async setProformaFreight (items, usrNo, usrCtrCd, usrPlcCd, usrAgtCd) {
    const strParams = []
    strParams.push('usrNo=' + usrNo)
    strParams.push('usrCtrCd=' + usrCtrCd)
    strParams.push('usrPlcCd=' + usrPlcCd)
    strParams.push('usrAgtCd=' + usrAgtCd)

    const arrParams = []
    // 개별 파일 Url 정보 셋팅
    const emailFiles = []
    items.forEach(p => {
      const tempParams = []
      tempParams.push('arrPortCd=' + p.portCd)
      tempParams.push('arrPayAgtorgNo=' + p.payAgtorgNo || '')
      tempParams.push('arrBlNo=' + p.blNo || '')
      tempParams.push('arrCstNo=' + p.cstNo || '')
      tempParams.push('arrTo=' + p.to || '')
      tempParams.push('arrRemark=' + p.remark || '')

      arrParams.push(tempParams.join('&'))
      emailFiles.push(p.blNo + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf/proforma-freight?' + tempParams.join('&') + '&' + strParams.join('&')
    })

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/proforma-freight?' + arrParams.join('&') + '&' + strParams.join('&')),
      emailUrl: REPORT_URL + '/report/email/proforma-freight?' + arrParams.join('&') + '&' + strParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('proformaFreight', rtn)
    return rtn
  },
  async setShippingOrder (items, usrNo, usrCtrCd, usrPlcCd, usrAgtCd) {
    const strParams = []
    strParams.push('usrNo=' + usrNo)
    strParams.push('usrCtrCd=' + usrCtrCd)
    strParams.push('usrPlcCd=' + usrPlcCd)
    strParams.push('usrAgtCd=' + usrAgtCd)

    console.log('setShippingOrder items >>>> ', items)

    const arrParams = []
    // 개별 파일 Url 정보 셋팅
    const emailFiles = []
    items.forEach(p => {
      const tempParams = []
      tempParams.push('arrBkgNo=' + p.bkgNo)
      tempParams.push('arrBlNo=' + p.srNo)
      tempParams.push('arrGubun=' + p.gubun || '')

      arrParams.push(tempParams.join('&'))
      emailFiles.push(p.bkgNo + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf/shipping-order?' + tempParams.join('&') + '&' + strParams.join('&')
    })

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/shipping-order?' + arrParams.join('&') + '&' + strParams.join('&')),
      emailUrl: REPORT_URL + '/report/email/shipping-order?' + arrParams.join('&') + '&' + strParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('shippingOrder', rtn)
    return rtn
  },
  async setFreeTime (items, usrNo, usrCtrCd, usrPlcCd, usrAgtCd) {
    const strParams = []
    strParams.push('usrNo=' + usrNo)
    strParams.push('usrCtrCd=' + usrCtrCd)
    strParams.push('usrPlcCd=' + usrPlcCd)
    strParams.push('usrAgtCd=' + usrAgtCd)

    const arrParams = []
    // 개별 파일 Url 정보 셋팅
    const emailFiles = []
    items.forEach(p => {
      const tempParams = []
      tempParams.push('arrBlNo=' + p.blNo || '')
      tempParams.push('arrDemDetAppNo=' + p.demDetAppNo.replace(',', ';') || '')
      tempParams.push('arrOrgCd=' + p.orgCd || '')
      tempParams.push('arrLangCtrCd=' + p.langCtrCd || '')
      tempParams.push('arrCtrCurCd=' + p.ctrCurCd || '')

      arrParams.push(tempParams.join('&'))
      emailFiles.push(p.blNo + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf/free-time?' + tempParams.join('&') + '&' + strParams.join('&')
    })

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/free-time?' + arrParams.join('&') + '&' + strParams.join('&')),
      emailUrl: REPORT_URL + '/report/email/free-time?' + arrParams.join('&') + '&' + strParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('freeTime', rtn)
    return rtn
  },
  async setJpClp (items, logInfo) {
    const strParams = []
    strParams.push('ctrCd=' + logInfo.logCtrCd)
    strParams.push('plcCd=' + logInfo.logPlcCd)
    strParams.push('userId=' + logInfo.logUserId)

    const arrParams = []
    // 개별 파일 Url 정보 셋팅
    const emailFiles = []
    items.forEach(p => {
      const tempParams = []
      tempParams.push('arrBlNo=' + p.blNo || '')

      arrParams.push(tempParams.join('&'))
      emailFiles.push(p.blNo + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf/jp-clp?' + tempParams.join('&') + '&' + strParams.join('&')
    })

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/jp-clp?' + arrParams.join('&') + '&' + strParams.join('&')),
      emailUrl: REPORT_URL + '/report/email/jp-clp?' + arrParams.join('&') + '&' + strParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('jpClp', rtn)
    return rtn
  },
  async setShipmentFinish (items, logInfo) {
    const strParams = []
    strParams.push('ctrCd=' + logInfo.logCtrCd)
    strParams.push('plcCd=' + logInfo.logPlcCd)
    strParams.push('userId=' + logInfo.logUserId)

    const arrParams = []
    // 개별 파일 Url 정보 셋팅
    const emailFiles = []
    items.forEach(p => {
      const tempParams = []
      tempParams.push('arrBkgNo=' + p.bkgNo || '')

      arrParams.push(tempParams.join('&'))
      emailFiles.push(p.bkgNo + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf/shipment-finish?' + tempParams.join('&') + '&' + strParams.join('&')
    })

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/shipment-finish?' + arrParams.join('&') + '&' + strParams.join('&')),
      emailUrl: REPORT_URL + '/report/email/shipment-finish?' + arrParams.join('&') + '&' + strParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('shipmentFinish', rtn)
    return rtn
  },
  async setReceiptPrint (items, usrNo, ctrCd, usrAgtCd, plcCd) {
    const strParams = []
    strParams.push('usrNo=' + usrNo)
    strParams.push('ctrCd=' + ctrCd)
    strParams.push('agtCd=' + usrAgtCd)
    strParams.push('plcCd=' + plcCd)

    const arrParams = []
    // 개별 파일 Url 정보 셋팅
    const emailFiles = []
    items.forEach(p => {
      const tempParams = []
      tempParams.push('arrRcvRno=' + p.rcvRno || '')
      tempParams.push('arrPrtRvstNo=' + p.prtRvstNo || '')
      tempParams.push('arrCstNo=' + p.cstNo || '')
      tempParams.push('arrCstNm=' + encodeURIComponent(p.cstNm) || '')
      tempParams.push('arrBlNo=' + ((p.blNo !== undefined && p.blNo !== null) ? p.blNo : ''))

      arrParams.push(tempParams.join('&'))
      emailFiles.push(p.rcvRno + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf/receipt-print?' + tempParams.join('&') + '&' + strParams.join('&')
    })
    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/receipt-print?' + arrParams.join('&') + '&' + strParams.join('&')),
      emailUrl: REPORT_URL + '/report/email/receipt-print?' + arrParams.join('&') + '&' + strParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('receiptPrint', rtn)
    return rtn
  },
  async setCargoMainFest (items, logInfo) {
    const strParams = []
    strParams.push('ctrCd=' + logInfo.logCtrCd)
    strParams.push('plcCd=' + logInfo.logPlcCd)
    strParams.push('userId=' + logInfo.logUserId)

    const arrParams = []
    // 개별 파일 Url 정보 셋팅
    const emailFiles = []
    items.forEach(p => {
      const tempParams = []
      tempParams.push('arrBlNo=' + p.blNo || '')
      tempParams.push('arrVslCd=' + p.vslCd || '')
      tempParams.push('arrVoyNo=' + p.voyNo || '')

      arrParams.push(tempParams.join('&'))
      emailFiles.push(p.blNo + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf/cargo-mainfest?' + tempParams.join('&') + '&' + strParams.join('&')
    })

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/cargo-mainfest?' + arrParams.join('&') + '&' + strParams.join('&')),
      emailUrl: REPORT_URL + '/report/email/cargo-mainfest?' + arrParams.join('&') + '&' + strParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('cargoMainFest', rtn)
    return rtn
  },
  async setInspecPrint (items, logInfo) {
    const strParams = []
    strParams.push('ctrCd=' + logInfo.logCtrCd)
    strParams.push('plcCd=' + logInfo.logPlcCd)
    strParams.push('userId=' + logInfo.logUserId)

    const arrParams = []
    // 개별 파일 Url 정보 셋팅
    const emailFiles = []
    items.forEach(p => {
      const tempParams = []
      tempParams.push('arrBlNo=' + p.blNo || '')
      tempParams.push('arrVslCd=' + p.vslCd || '')
      tempParams.push('arrVoyNo=' + p.voyNo || '')

      arrParams.push(tempParams.join('&'))
      emailFiles.push(p.blNo + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf/inspec-print?' + tempParams.join('&') + '&' + strParams.join('&')
    })

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/inspec-print?' + arrParams.join('&') + '&' + strParams.join('&')),
      emailUrl: REPORT_URL + '/report/email/inspec-print?' + arrParams.join('&') + '&' + strParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('inspec-print', rtn)
    return rtn
  },
  async setOutCommission (items, usrNo, ctrCd, type, plcCd) {
    const strParams = []
    strParams.push('usrNo=' + usrNo)
    strParams.push('ctrCd=' + ctrCd)
    strParams.push('type=' + type)
    strParams.push('plcCd=' + plcCd)

    const arrParams = []
    // 개별 파일 Url 정보 셋팅
    const emailFiles = []
    items.forEach(p => {
      const tempParams = []
      tempParams.push('arrPortCd=' + p.portCd || '')
      tempParams.push('arrGrpCatCd=' + p.grpCatCd || '')
      tempParams.push('arrReqNo=' + p.reqNo || '')
      tempParams.push('arrParm1=' + p.parm1 || '')

      arrParams.push(tempParams.join('&'))
      if (type === 1) {
        emailFiles.push(p.reqNo + '.pdf')
      } else {
        emailFiles.push(p.parm1 + '.pdf')
      }
      p.pdfUrl = REPORT_URL + '/report/pdf/out-commission?' + tempParams.join('&') + '&' + strParams.join('&')
    })

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/out-commission?' + arrParams.join('&') + '&' + strParams.join('&')),
      emailUrl: REPORT_URL + '/report/email/out-commission?' + arrParams.join('&') + '&' + strParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('outCommission', rtn)
    return rtn
  },
  // 긴급양하 요청
  async setUrgentGetOff (items, usrNo, ctrCd) {
    const strParams = []
    strParams.push('usrNo=' + usrNo)
    strParams.push('ctrCd=' + ctrCd)

    const arrParams = []
    // 개별 파일 Url 정보 셋팅
    const emailFiles = []
    items.forEach(p => {
      const tempParams = []
      tempParams.push('arrPortCd=' + p.portCd || '')
      tempParams.push('arrParm1=' + p.parm1 || '')

      arrParams.push(tempParams.join('&'))
      emailFiles.push(p.parm1 + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf/urgent-getoff?' + tempParams.join('&') + '&' + strParams.join('&')
    })

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/urgent-getoff?' + arrParams.join('&') + '&' + strParams.join('&')),
      emailUrl: REPORT_URL + '/report/email/urgent-getoff?' + arrParams.join('&') + '&' + strParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('urgentGetOff', rtn)
    return rtn
  },
  async setShippingRequest (items, usrVo) {
    const strParams = []
    strParams.push('ctrCd=' + usrVo.ctrCd)
    strParams.push('bkgPlcCd=' + usrVo.bkgPlcCd)
    strParams.push('userId=' + usrVo.userId)
    strParams.push('agtCd=' + usrVo.agtCd)
    strParams.push('ctrCurCd=' + usrVo.ctrCurCd)

    const arrParams = []
    const emailFiles = []

    // 개별 파일 Url 정보 셋팅
    items.forEach(p => {
      const tempParams = []
      tempParams.push('arrBlNo=' + p.blNo)
      tempParams.push('arrRiderYn=' + p.riderYn || '')
      tempParams.push('arrPorDlyYn=' + p.porDlyYn || '')
      tempParams.push('arrNoCnee=' + p.noCnee || '')
      tempParams.push('arrNoNotify=' + p.noNotify || '')
      tempParams.push('arrClauseYn=' + p.clauseYn || '')
      tempParams.push('arrExRateYn=' + p.exRateYn || '')
      tempParams.push('arrOrgCd=' + p.orgCd || '')
      tempParams.push('arrLangCtrCd=' + p.langCtrCd || '')
      tempParams.push('arrCtrCurCd=' + p.ctrCurCd || '')

      arrParams.push(tempParams.join('&'))
      emailFiles.push(p.blNo + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf/shipping-request?' + tempParams.join('&') + '&' + strParams.join('&')
    })

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/shipping-request?' + arrParams.join('&') + '&' + strParams.join('&')),
      emailUrl: REPORT_URL + '/report/email/shipping-request?' + arrParams.join('&') + '&' + strParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('shippingRequest', rtn)
    return rtn
  },
  async setInvoice (items, usrNo, usrCtrCd, usrPlcCd, usrAgtCd) {
    const strParams = []
    strParams.push('usrNo=' + usrNo)
    strParams.push('usrCtrCd=' + usrCtrCd)
    strParams.push('usrPlcCd=' + usrPlcCd)
    strParams.push('usrAgtCd=' + usrAgtCd)

    const arrParams = []
    // 개별 파일 Url 정보 셋팅
    const emailFiles = []
    items.forEach(p => {
      const tempParams = []
      tempParams.push('arrPortCd=' + p.portCd)
      tempParams.push('arrPayAgtorgNo=' + p.payAgtorgNo || '')
      tempParams.push('arrBlNo=' + p.blNo || '')
      tempParams.push('arrCstNo=' + p.cstNo || '')
      tempParams.push('arrTo=' + p.to || '')
      tempParams.push('arrRemark=' + p.remark || '')

      arrParams.push(tempParams.join('&'))
      emailFiles.push(p.blNo + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf/invoice?' + tempParams.join('&') + '&' + strParams.join('&')
    })

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/invoice?' + arrParams.join('&') + '&' + strParams.join('&')),
      emailUrl: REPORT_URL + '/report/email/invoice?' + arrParams.join('&') + '&' + strParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('invoice', rtn)
    return rtn
  },
  async setCreditPrint (refNo, cstCd, ctrCd, eiCatCd, logInfo) {
    const strParams = []
    strParams.push('refNo=' + refNo)
    strParams.push('cstCd=' + cstCd)
    strParams.push('ctrCd=' + ctrCd)
    strParams.push('eiCatCd=' + eiCatCd)

    strParams.push('userId=' + logInfo.logUserId)
    strParams.push('plcCd=' + logInfo.logPlcCd)

    const rtn = {
      // pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/credit-billing-details?' + strParams.join('&')),
      pdfUrl: process.env.VUE_APP_SETTLE_URL + '/settle/credit/print?' + strParams.join('&'),
      emailUrl: REPORT_URL + '/report/email/credit-billing-details?' + strParams.join('&'),
      emailFiles: [
        refNo + '.pdf'
      ],
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('credit-billing-details', rtn)

    return rtn
  },
  async setContainerReturn (item, logInfo) {
    console.log('setContainerReturn')

    const strParams = []
    strParams.push('invIssNo=' + item.invIssNo)
    strParams.push('invRno=' + item.invIssNo)
    strParams.push('to=' + encodeURIComponent(item.cstEnm))
    strParams.push('usrNm=' + '')
    strParams.push('telNo=' + '')
    strParams.push('rmk=' + '')
    strParams.push('inOut=' + item.eicatCd)
    strParams.push('agtCd=' + item.agtOrgNo)
    strParams.push('demDetCatCd=' + item.demDetCatCd)
    strParams.push('totAmt=' + item.totAmt)
    strParams.push('printedBy=' + '')
    strParams.push('refNo=' + item.blNo)

    strParams.push('userId=' + logInfo.logUserId)
    strParams.push('ctrCd=' + logInfo.logCtrCd)
    strParams.push('plcCd=' + logInfo.logPlcCd)

    const rtn = {
      // pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/container-return?' + strParams.join('&')),
      pdfUrl: REPORT_URL + '/report/pdf/container-return?' + strParams.join('&'),
      emailUrl: REPORT_URL + '/report/email/container-return?' + strParams.join('&'),
      emailFiles: [
        item.invIssNo + '.pdf'
      ],
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('container-return', rtn)

    return rtn
  },
  async setImpBusAgePdfPop (item, logInfo) {
    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/power-of-attorney?type=' + item.type + '&reqNo=' + item.reqNo + '&grpCatCd=' + item.grpCatCd +
      '&ctrCd=' + logInfo.logCtrCd + '&userId=' + logInfo.logUserId + '&plcCd=' + logInfo.logPlcCd),
      downloadUrl: REPORT_URL + '/report/pdf/power-of-attorney?type=' + item.type + '&reqNo=' + item.reqNo + '&grpCatCd=' + item.grpCatCd,
      emailUrl: REPORT_URL + '/report/email/power-of-attorney?type=' + item.type + '&reqNo=' + item.reqNo + '&grpCatCd=' + item.grpCatCd,
      emailFiles: [item.reqNo + '.pdf'],
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('dem-invoice111', rtn)
    return rtn
  },
  async setDemInvoice (data, plcCd) {
    let queryParams = ''
    console.log(data)
    queryParams += 'invIssNo=' + data.invoice.invIssNo
    queryParams += '&invRno=' + data.invoice.invIssNo //?
    queryParams += '&to=' //+ data.usrNm.replace(/(\s*)/g, '')
    queryParams += '&usrNm=' //+ data.usrNm.replace(/(\s*)/g, '')
    queryParams += '&telNo=' + '1'
    queryParams += '&rmk=' + '1'
    queryParams += '&inOut=' + data.eiCatCd
    queryParams += '&isAgtCd=' + 'TLCTLC' //?
    queryParams += '&demDetCatCd=' + data.invoice.demDetCatCd
    queryParams += '&invKnd=' + '02' //?
    queryParams += '&bizCatCd=' + '06'
    queryParams += '&refNo=' + data.blNo
    queryParams += '&printedBy=' + 'KLTC'
    queryParams += '&esign=' + '1'
    queryParams += '&ctrCd=' + data.ctrCd
    queryParams += '&plcCd=' + plcCd

    console.log(REPORT_URL + '/report/pdf/issued-dem-invoice?' + queryParams)

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/issued-dem-invoice?' + queryParams),
      downloadUrl: REPORT_URL + '/report/pdf/issued-dem-invoice?' + queryParams,
      emailUrl: REPORT_URL + '/report/email/issued-dem-invoice?' + queryParams,
      emailFiles: [data.invoice.invIssNo + '.pdf'],
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('dem-invoice', rtn)
    return rtn
  },
  async setDemInvoiceMulti (items, params) {
    const emailFiles = []
    const arrParams1 = []
    const arrParams2 = []

    arrParams1.push('to=')
    arrParams1.push('usrNm=')
    arrParams1.push('telNo=')
    arrParams1.push('rmk=')
    arrParams1.push('inOut=' + params.eiCatCd)
    arrParams1.push('isAgtCd=' + params.agtCd)
    arrParams1.push('demDetCatCd=' + params.demDetCatCd)
    arrParams1.push('invKnd=02')
    arrParams1.push('bizCatCd=06')
    arrParams1.push('printedBy=KLTC')
    arrParams1.push('esign=1')
    arrParams1.push('ctrCd=' + params.ctrCd)

    items.forEach(p => {
      emailFiles.push(p.blNo + '.pdf')
      const p2 = []
      p2.push('arrInvIssNo=' + (p.invIssNo || ''))
      p2.push('arrInvRno=' + (p.invRno || ''))
      p2.push('arrRefNo=' + p.blNo)

      arrParams2.push('arrInvIssNo=' + (p.invIssNo || ''))
      arrParams2.push('arrInvRno=' + (p.invRno || ''))
      arrParams2.push('arrRefNo=' + p.blNo)

      p.pdfUrl = REPORT_URL + '/report/pdf/issued-dem-invoice?' + p2.join('&') + arrParams1.join('&')
    })

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/issued-dem-invoice?' + arrParams1.join('&') + '&' + arrParams2.join('&')),
      downloadUrl: REPORT_URL + '/report/pdf/issued-dem-invoice?' + arrParams1.join('&') + '&' + arrParams2.join('&'),
      emailUrl: REPORT_URL + '/report/email/issued-dem-invoice?' + arrParams1.join('&') + '&' + arrParams2.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('dem-invoice', rtn)
    return rtn
  },
  async setCombineInvoice (items, { usrNo, ctrCd, plcCd, agtNo, agtCd, payAgtOrgCd, to, totCal, eiCatCd }) {
    const strParams = []
    let paramAgtNo = agtNo === undefined || agtNo === '' || agtNo === null ? agtCd : agtNo

    strParams.push('usrNo=' + usrNo)
    strParams.push('ctrCd=' + ctrCd)
    strParams.push('plcCd=' + plcCd)
    strParams.push('agtNo=' + paramAgtNo)
    strParams.push('agtOrgNo=' + paramAgtNo)
    strParams.push('payAgtOrgCd=' + payAgtOrgCd)
    strParams.push('arrTo=' + encodeURIComponent(to))

    if (ctrCd === 'IN') {
      strParams.push('totCal=' + totCal)
      strParams.push('eiCatCd=' + eiCatCd)
    }

    const arrParams = []
    // 개별 파일 Url 정보 셋팅
    const emailFiles = []
    const len = items.length
    let p
    let cnt = 0
    let rejectItem = null

    for (let i = 0; i < len; i++) {
      p = items[i]
      if (p.authYn !== 'Y' || p.exrateYn !== 'Y') {
        if (rejectItem === null) {
          rejectItem = items[i]
        }
        continue
      } else if (p.proformaYn !== 'Y' && (p.invIssNo === undefined || p.invIssNo === '')) {
        if (rejectItem === null) {
          rejectItem = items[i]
        }
        continue
      }
      const tempParams = []
      tempParams.push('arrBlNo=' + p.blNo || '')
      tempParams.push('arrInvIssNo=' + p.invIssNo || '')
      tempParams.push('arrInvIssDt=' + p.issDt || '')
      tempParams.push('arrInvIssCatCd=' + p.invIssCatCd || '')
      tempParams.push('arrInvKndCd=' + p.invKndCd || '')
      // tempParams.push('arrTo=' + p.to || '')
      tempParams.push('arrRemark=' + encodeURIComponent(p.remark) || '')
      tempParams.push('arrFilter=' + p.filter || '')
      tempParams.push('arrEsign=' + p.esign || '')
      tempParams.push('arrCstNo=' + encodeURIComponent(p.cstNo) || '')
      tempParams.push('arrProformaYn=' + p.proformaYn || '')
      arrParams.push(tempParams.join('&'))
      const emailFileNm = p.invIssNo || p.blNo
      emailFiles.push(emailFileNm + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf/combine-invoice?' + tempParams.join('&') + '&' + strParams.join('&')
      cnt++
    }

    let rtn
    if (cnt > 0) {
      rtn = {
        pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/combine-invoice?' + arrParams.join('&') + '&' + strParams.join('&')),
        emailUrl: REPORT_URL + '/report/email/combine-invoice?' + arrParams.join('&') + '&' + strParams.join('&'),
        emailFiles: emailFiles,
        pg: 1,
        pageCount: 0,
        currentPage: 0,
        rejectItem: null
      }
    } else {
      rtn = {
        pdfUrl: '',
        emailUrl: '',
        rejectItem: rejectItem
      }
    }
    this.printLog('combineInvoice', rtn)
    return rtn
  },
  async setChangeNotice (items, usrNo, logInfo) {
    const strParams = []
    strParams.push('usrNo=' + usrNo)
    strParams.push('plcCd=' + logInfo.logPlcCd)
    strParams.push('ctrCd=' + logInfo.logCtrCd)

    const arrParams = []
    // 개별 파일 Url 정보 셋팅
    const emailFiles = []
    items.forEach(p => {
      const tempParams = []
      tempParams.push('arrVslCd=' + p.vslCd || '')
      tempParams.push('arrVoyNo=' + p.voyNo || '')
      tempParams.push('arrPolPortCd=' + p.polPortCd || '')
      tempParams.push('arrPodPortCd=' + p.podPortCd || '')
      tempParams.push('arrGubun=' + p.gubun || '')
      tempParams.push('arrDocNo=' + p.docNo || '')
      tempParams.push('arrEditTable=' + p.editTable || '')
      tempParams.push('arrInOut=' + p.bound || '')

      arrParams.push(tempParams.join('&'))
      emailFiles.push(p.voyNo + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf/change-notice?' + tempParams.join('&') + '&' + strParams.join('&')
    })

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/change-notice?' + arrParams.join('&') + '&' + strParams.join('&')),
      emailUrl: REPORT_URL + '/report/email/change-notice?' + arrParams.join('&') + '&' + strParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('changeNotice', rtn)
    return rtn
  },
  async setSurrenderNotice (items, userEnam, logInfo) {
    const strParams = []
    strParams.push('usrNm=' + encodeURIComponent(userEnam))
    strParams.push('userId=' + logInfo.logUserId)
    strParams.push('ctrCd=' + logInfo.logCtrCd)
    strParams.push('plcCd=' + logInfo.logPlcCd)

    const arrParams = []
    // 개별 파일 Url 정보 셋팅
    const emailFiles = []
    items.forEach(p => {
      const tempParams = []
      tempParams.push('arrBlNo=' + p.blNo || '')
      arrParams.push(tempParams.join('&'))
      emailFiles.push(p.blNo + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf/surrender-notice?' + tempParams.join('&') + '&' + strParams.join('&')
    })

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/surrender-notice?' + arrParams.join('&') + '&' + strParams.join('&')),
      emailUrl: REPORT_URL + '/report/email/surrender-notice?' + arrParams.join('&') + '&' + strParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('surrenderNotice', rtn)
    return rtn
  },
  async setSeawaybillNotice (items, userEnam, logInfo) {
    const strParams = []
    strParams.push('usrNm=' + encodeURIComponent(userEnam))
    strParams.push('userId=' + logInfo.logUserId)
    strParams.push('ctrCd=' + logInfo.logCtrCd)
    strParams.push('plcCd=' + logInfo.logPlcCd)

    const arrParams = []
    // 개별 파일 Url 정보 셋팅
    const emailFiles = []
    items.forEach(p => {
      const tempParams = []
      tempParams.push('arrBlNo=' + p.blNo || '')
      arrParams.push(tempParams.join('&'))
      emailFiles.push(p.blNo + '.pdf')
      p.pdfUrl = REPORT_URL + '/report/pdf/seawaybill-notice?' + tempParams.join('&') + '&' + strParams.join('&')
    })

    const rtn = {
      pdfUrl: await this.getBlobData(REPORT_URL + '/report/pdf/seawaybill-notice?' + arrParams.join('&') + '&' + strParams.join('&')),
      emailUrl: REPORT_URL + '/report/email/seawaybill-notice?' + arrParams.join('&') + '&' + strParams.join('&'),
      emailFiles: emailFiles,
      pg: 1,
      pageCount: 0,
      currentPage: 0
    }
    this.printLog('seawaybillNotice', rtn)
    return rtn
  },
  async getBlobData (url) {
    let token = sessionStorage.getItem('access_token')
    let rtn = ''
    store.commit('startSpinner')
    await axios({
      url: url,
      method: 'GET',
      //headers: { jwt: token },
      responseType: 'arraybuffer'
    }).then(async (response) => {
      rtn = response.data
    }).finally(() => {
      store.commit('endSpinner')
    })
    return rtn
  },
  download (url, fileName) {
    axios({
      url: url,
      method: 'GET',
      responseType: 'blob'
    }).then((response) => {
      if (window.navigator.msSaveOrOpenBlob) {
        // IE 11
        window.navigator.msSaveOrOpenBlob(new Blob([response.data]), fileName)
      } else {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', fileName)
        document.body.appendChild(fileLink)

        fileLink.click()
        fileLink.remove()
        window.URL.revokeObjectURL(fileURL)
      }
    })
  },
  async sendEmail (THIS, callUrl, params) {
    // let url = callUrl
    // const bodyReq = { ...params }

    // if (callUrl.indexOf('?') > -1) {
    //   url = callUrl.substring(0, callUrl.indexOf('?'))
    //   const arrParams = callUrl.substring(callUrl.indexOf('?') + 1).split('&')

    //   arrParams.forEach(str => {
    //     const k = str.substring(0, str.indexOf('='))
    //     const v = str.substring(str.indexOf('=') + 1)

    //     if (bodyReq[k] === undefined) {
    //       if (k.indexOf('arr') === 0) {
    //         bodyReq[k] = [v]
    //       } else {
    //         bodyReq[k] = v
    //       }
    //     } else if (typeof bodyReq[k] === 'string') {
    //       bodyReq[k] = [bodyReq[k], v]
    //     } else {
    //       bodyReq[k].push(v)
    //     }
    //   })
    // }

    // console.log('url ->', url)
    // console.log('bodyReq ->', bodyReq)

    await axios({
      url: callUrl,
      method: 'GET',
      params: params
    }).then(async (response) => {
      console.log(response)
      if (response.data.code === 'C0000') {
        await THIS.$ekmtcCommon.asyncAlertMessage({ message: THIS.$t('msg.REPORT_PDF.901') }) // 메일발송 처리 되었습니다.
        THIS.$emit('close')
      } else {
        await THIS.$ekmtcCommon.asyncAlertMessage({ message: THIS.$t(response.data.message) })
      }
    }).catch(async (err) => {
      console.log(err)
      await THIS.$ekmtcCommon.asyncAlertMessage({ message: THIS.$t('msg.REPORT_PDF.999') })
    })
  },
  commonInit () {
    // layer Popup 띄울때 아래와 같이 처리 되어 있어서 PDF 출력할때 1page 만 출력 되는 문제가 있어서 해당 style 제거
    // $('body,html').css('overflow', 'hidden')

    setTimeout(function () {
      $('body,html').removeAttr('style')
    }, 1000)
  },
  layerOpen (cssSelctor) {
    $(cssSelctor).fadeIn()
    $('body,html').css('overflow', 'hidden')
  },
  layerClose (cssSelctor) {
    $(cssSelctor).fadeOut()
  },
  printLog (tag, obj) {
    if (this.isNotPRD()) {
      console.log('----------', tag, '----------')
      console.log(obj)
    }
  },
  isNotPRD () {
    return process.env.VUE_APP_MODE !== 'PRD'
  },
  isNotSTG () {
    return process.env.VUE_APP_MODE !== 'STG'
  }
}
